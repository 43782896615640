import React, { Fragment } from 'react'
import "./index.css"
import logo from "../../assets/images/hamirholdngblancw.png"
import logo2 from "../../assets/images/hamirholding.png"
export default function Logo({isTransparent}) {
    const img=isTransparent?logo:logo2
  return (
    <Fragment>
        <img className='logo' height={50+"px"} src={img} alt="Hamir Holding logo" />
    </Fragment>
  )
}
