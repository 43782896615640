import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loading from '../shared/loading';
import LeFooter from '../core/components/footer';
import Navbar from '../core/components/navbar';

// Lazy-loaded components
const Accueil = React.lazy(() => import('../features/accueil'));
const Detail = React.lazy(() => import('../features/detail'));
const Error404 = React.lazy(() => import('../shared/error404'));
const Scroll = React.lazy(() => import('../shared/scroll'));

function App() {

  return (
    <Suspense fallback={<Loading />}>
    <BrowserRouter>
          <Navbar/>
            <Routes>
              <Route path='/' element={<Accueil/>} />
              <Route path='/partenaires/:category' element={<Detail/>} />
              <Route path='*' element={<Error404/>} />
            </Routes>
            <LeFooter/>
            <Scroll/>
        </BrowserRouter>
    </Suspense>
  );
}

export default App;

// import { useEffect, useState } from 'react';
// import Accueil from '../features/accueil';
// import Scroll from '../shared/scroll';
// import './App.css';
// import { BrowserRouter, Route,Routes} from 'react-router-dom';
// import Loading from '../shared/loading';
// import LeFooter from '../core/components/footer';
// import Navbar from '../core/components/navbar';
// import Detail from '../features/detail';
// import Error404 from '../shared/error404';

// function App() {
//   const [isLoading,setIsLoading]=useState(true)
//   const onPageLoad = () => {
//     setIsLoading(false);
//   }
//   useEffect(()=>{

//     // Check if the page has already loaded
//     switch (document.readyState) {
//       case "loading":
//           window.addEventListener('load', onPageLoad);
//         break;
//         case "interactive": 
//           setIsLoading(false)
//         break;
//       default:
//         setTimeout(onPageLoad,6000)
//         break;

//     }
//     // if (document.readyState === 'complete') {
//     //   onPageLoad();
//     // } else {
//     //   window.addEventListener('load', onPageLoad);
//     //   // Remove the event listener when component unmounts
    
//     // }
//       return () => window.removeEventListener('load', onPageLoad);
//   },[])
//   // window.addEventListener("load",()=>{
//   //     setIsLoading(false)
//   // })
//   // window.addEventListener("contextmenu", e => e.preventDefault());
//   return (
//     isLoading?
//     <Loading/>
//     :
//     <BrowserRouter>
//       <Navbar/>
//       <Routes>
//         <Route path='/' element={<Accueil/>} ></Route>
//         <Route path='/partenaires/:category' element={<Detail/>} ></Route>
//         <Route path='*' element={<Error404/>}></Route>
//       </Routes>
//       <LeFooter/>
//       <Scroll/>
//       </BrowserRouter>
//   );
// }

// export default App;
