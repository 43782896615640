import React from 'react'
import './index.css'
import sotilma from '../../assets/images/hamirholding-logo.svg'

export default function Loading() {
  return (
    <div id="loading">
        <div className="loading-img">
            <img src={sotilma} alt="LOGO HAMIR HOLDING"/>
            <div className="loading-shape"></div>
        </div>
    </div>
  )
}
