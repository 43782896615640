import React, { useEffect, useState } from 'react';
import Logo from '../../../shared/logo';
import "./index.css";

const Navbar = () => {
  const [navBar, setNavBar] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 992) {
      setNavBar(false);
    } else {
      loadNavbar();
    }
  };

  const handleScroll = () => {
    if (window.innerWidth > 992) {
      changeNavbar();
    }
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const changeNavbar = () => {
    if (window.scrollY > 100) {
      setNavBar(false);
    } else {
      loadNavbar();
    }
  };

  const loadNavbar = () => {
    if (window.innerWidth > 992) {
      setNavBar(window.scrollY < 150);
    } else {
      setNavBar(false);
    }
  };

  useEffect(() => {
    loadNavbar(); // Initial check
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", loadNavbar);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", loadNavbar);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isTransparent = navBar ? " transparent" : "";

  return (
    <header id='header' className={`fixed-top${isTransparent}`}>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <a className="navbar-brand" href="/#" onClick={scrollTop}>
            <Logo isTransparent={navBar} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
            <li className="nav-item active">
                    <a className="nav-link" onClick={scrollTop} href="/#">Accueil</a>
                </li> 
                <li className="nav-item">
                    <a className="nav-link" href="/#apropos" >A propos</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/#investment" >Investissements</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/#partenaires" >Partenaires</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/#actualites"  >Actualités</a>
                </li>
                <li className="nav-item ms-4 d-md-flex justify-content-md-center">
                    <a className="nav-link btn btn-2 btn-primary" href="#contact">Contactez-nous</a>
                </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;

// import React, { useEffect, useState } from 'react'
// import Logo from '../../../shared/logo'
// import "./index.css"
// export default function Navbar() {
  
//     const [navBar,setNavBar]=useState(true)
//     // const [isTop,setIsTop]=useState(true)
//     useEffect(()=>{
//         if(window.innerWidth<992){
//             setNavBar(false)
//         }
//     }, [])
//     const scrollTop=()=>{
//         window.scrollTo(0,0)
//     }
//     const changeNavbar=()=>{
//         if(window.innerWidth>992){
//             if(window.scrollY>100){
//                 setNavBar(false)
//             }else{
//                 setNavBar(true)
//             }
//         }else{
//             setNavBar(false)
//         }
//     }
//     const loadNavbar=()=>{
//         if(window.innerWidth>992){
//                if(window.scrollY<150) setNavBar(true)
//             //    setIsTop(true)
//         }else{
//                 setNavBar(false)
//                 // setIsTop(false)
                
//         }
//     }
//     window.addEventListener("resize",loadNavbar)
//     window.addEventListener("load",loadNavbar)
//     window.addEventListener("scroll",changeNavbar)
//     const isTransparent=navBar?" transparent":""
//     // const topBottom=isTop?"fixed-top":"fixed-bottom"
//     return (
//         <header id='header' className={"fixed-top"+isTransparent}>
//             <div className="container">
//             <nav className="navbar navbar-expand-lg navbar-light">
//             <a className="navbar-brand" href={"/#"} onClick={scrollTop}><Logo isTransparent={navBar}/></a>
//             <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
//                 <span className="navbar-toggler-icon"></span>
//             </button>
//             <div className="collapse navbar-collapse" id="navbarNavDropdown">
//                 <ul className="navbar-nav  ms-auto">
                // <li className="nav-item active">
                //     <a className="nav-link" onClick={scrollTop} href="/#">Accueil</a>
                // </li> 
                // <li className="nav-item">
                //     <a className="nav-link" href="/#apropos" >A propos</a>
                // </li>
                // <li className="nav-item">
                //     <a className="nav-link" href="/#investment"  preventScrollReset={false} >Investissements</a>
                // </li>
                // <li className="nav-item">
                //     <a className="nav-link" href="/#partenaires" >Partenaires</a>
                // </li>
                // <li className="nav-item">
                //     <a className="nav-link" href="/#actualites"  >Actualités</a>
                // </li>
                // <li className="nav-item ms-4 d-md-flex justify-content-md-center">
                //     <a className="nav-link btn btn-2 btn-primary" href="#contact">Contactez-nous</a>
                // </li>
//                 </ul>
//             </div>
//             </nav>
//             </div>
//         </header>
//     )
// }
