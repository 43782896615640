import React from 'react'
import './index.css'
import logo from "../../../assets/images/hamirholdngblanc.png"
import {BsTelephone,BsFacebook,BsLinkedin} from 'react-icons/bs'
import { FaMapMarkerAlt} from 'react-icons/fa'
import { HiOutlineMail} from 'react-icons/hi'
import { Link } from 'react-router-dom'

export default function LeFooter() {
  // {/* lani-lani-_ */}
  return (
    <footer>
        <div className="footer-top">
          <div className="container">
          <div className="row gx-4">
            <div className="col-md-4">
              <img className="px-5" src={logo} alt="Hamir holding" />
              <p>H2 est une société holding dynamique et diversifiée, spécialisée dans l'investissement et la gestion de participations dans un large éventail d'entreprises. Notre objectif est de créer de la valeur à long terme pour nos actionnaires en identifiant et en soutenant des opportunités de croissance stratégiques.</p>
            </div>
            <div className="col-5 col-md-4">
              <h4>LIENS UTILES</h4>
              <ul className='navbar-nav'>
                <li className='nav-item'><Link className='nav-link' to="/">Accueil</Link></li>
                <li className='nav-item'><a className='nav-link' href="#apropos">A propos</a></li>
                <li className='nav-item'><a className='nav-link' href="#investment">Investissements</a></li>
                <li className='nav-item'><a className='nav-link' href="#partenaires">Partenaires</a></li>
                <li className='nav-item'><a className='nav-link' href="#actualites">Actualités</a></li>
              </ul>  
            </div>
            <div className="col-7 col-md-4">
              <h4>CONTACTS</h4>
              <ul className='navbar-nav'>
                <li className='nav-item'><a className='nav-link' href="tel:00221784390000"><BsTelephone/> (+221) 78 439 00 00</a></li>
                <li className='nav-item'><FaMapMarkerAlt/> Yoff, Dakar-SENEGAL</li>
                <li className='nav-item'><a className='nav-link' href="mailto:contact@hamirholding.com"><HiOutlineMail/> contact@hamirholding.com</a></li>
              </ul>
              <h5 className='mt-3'>Rester connecté</h5>
              <ul className='nav'>
                <li className='nav-item'><a className='nav-link no-unlink' href="/#"><BsFacebook size={20}/></a></li>
                <li className='nav-item'><a className='nav-link no-unlink' href="/#"><BsLinkedin size={20}/></a></li>
                <li className='nav-item'><a className='nav-link no-unlink' href="mailto:contact@hamirholding.com"><HiOutlineMail size={20}/></a></li>
              </ul>
            </div>
          </div>
          </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
            <p className='text-center text-light'>&copy; 2023 copyright by <a href="https://www.linkedin.com/in/ibrahima-gning-744189161/" alt="Ibrahima GNING linkedin">Bizicorp</a></p>
            </div>
        </div>
    </footer>
  )
  }